.leaflet-popup-content {
    text-align: left;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.popup__title {
    font-size: 19px;
    margin: 10px;
}

.popup__menu {
    list-style: none;
    margin: 10px;

    padding-left: 20px;
}

.item {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.description,
.site {
    font-size: 14px;
}

.popup__link {
    color: #0029FF;
    display: flex;
}

.popup__link img {
    margin-left: 5px;
}



